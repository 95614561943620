!function (global) {
  'use strict';

  var previousSearchBox = global.SearchBox;

  function SearchBox(options) {
    var options = options || {};
    var searchBoxAutocomplete;
    var searchUrl;
    var $input;
    var $form;

    // Helpers
    var isFirstSearch = true;

    // Previous queries
    var queryList = [];
    var $previousQueries;

    function init() {
      $input = options.$input;
      $form = options.$form;

      searchUrl = $form.data('search-url');

      // Init typeahead
      searchBoxAutocomplete = new AutocompleteBox({
        $input: $input,
        url: $input.data('search-url')
      });

      // Bindings
      // Form
      $form.bind('keypress keydown keyup', function (e) {
        if (e.keyCode == 13) {
          goToSearch(getQuery($input));
          return false;
        }
      });

      $form.submit(function (event) {
        goToSearch(getQuery($input));
        return false;
      });

      // Typeahead
      $input.on('typeahead:select', function (event, suggestion) {
        goToSearch(suggestion);
      });
    }

    function setPlaceHolder(placeHolderText) {
      $input.attr("placeholder", capitalizeFirstLetter(placeHolderText));
    }

    function removeSpecialChars(text) {
      return encodeURI(text.replace(/[^a-zA-ZÀ-ú0-9 +.&"'-]/g, "").replace('&', '%26').trim());
    }

    function capitalizeFirstLetter(text) {
      text = text.toLowerCase();
      return text.charAt(0).toUpperCase() + text.slice(1);
    }

    function getQuery($inputs) {
      var queryReturn = "";
      if ($inputs.length === 1) {
        queryReturn= $inputs.typeahead('val');
      } else {
        $inputs.each(function(idx, ctrl) {
          var $ctrl = $(ctrl);
          if ($ctrl.is(':visible')) {
            queryReturn=$ctrl.typeahead('val');
          }
        });
      }
      return queryReturn;
    }

    function goToSearch(query) {
      var search;
      var cleanQuery;

      if (isFirstSearch) {
        if (query != null && query != undefined && query.length > 0) {
          isFirstSearch = false;

          cleanQuery = removeSpecialChars(query);
          cleanQuery = cleanQuery.replace(/\+/g, '%2B').replace(/\s/g, '+');
          search = searchUrl + "?typeSearch=2&trabajo=" + cleanQuery;

          window.location.href = search;
        }
        else {
          search = searchUrl + "?typeSearch=2";
          window.location.href = search;
        }
      }
    }

    init();

    return {
      setPlaceHolder: setPlaceHolder
    }
  }

  SearchBox.noConflict = function noConflict() {
    global.SearchBox = previousSearchBox;
    return SearchBox;
  };

  global.SearchBox = SearchBox;
}(this);
